import React from 'react';
import '../styles/main-pages.css';
import '../styles/AboutUs.css';
import BookingButton from '../components/common/BookingButton';
import Reveal from '../components/common/Reveal';
import WhyUsSection from '../components/WhyUsSection';

function Contact() {
  return (
    <div className='page-container'>
      <div className="breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcumb-wrap text-center">
                <h2>About us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-5'>
        <div className='about-page-section-1 py-3'>
          <div className='about-page-child-section'>
            <Reveal>

              <p className='py-1'>
                At Tes Barber, located in the vibrant heart of North London near Finsbury Park and the iconic Emirates Stadium, we've been shaping style and confidence since 2012. Our journey began with a simple yet powerful idea: to blend traditional barbering techniques with a modern twist, offering a space where everyone can look and feel their best.
              </p>
              <p className='py-1'>
                From our humble beginnings at 217 Seven Sisters Road, we've grown into a local favorite, known for our warm atmosphere, skilled barbers, and commitment to quality. Over the years, we've expanded our range of services to meet the diverse needs of our community—whether it's a sharp haircut, a meticulous beard trim, or a rejuvenating facial, we’re here to help you look your best.
              </p>
              <p className='py-1'>
                Our passion for the craft and dedication to our clients has made Tes Barber more than just a barbershop; it's a place where tradition meets style, and everyone is welcome.
              </p>

            </Reveal>
          </div>
          <div className='about-page-child-section py-2'>
            <Reveal>
              <img src={require('../assets/images/img3.jpg')} alt='Tes barber' width='100%' style={{ borderRadius: 15, maxHeight: 500, objectFit: 'contain' }} />
            </Reveal>
          </div>
        </div>

        <Reveal>
          <div className='icon-container'>
            <img src={require('../assets/images/scissor-icon.png')} className="scissor-img" alt="scissor" />
            <h4>Our mission</h4>
          </div>
        </Reveal>
        <div className='about-page-section-1'>

          <div className='about-page-child-section-1 py-4'>
            <Reveal>
              <p>
                At Tes Barber, our mission is simple: to deliver exceptional grooming experiences that make you feel confident, stylish, and refreshed. We believe in the artistry of barbering, where every cut is a craft, every shave a ritual, and every visit an experience.
              </p>
              <div className='d-flex ps-3'>
                <i className="bi bi-caret-right text-white"></i>
                <p><strong>Quality</strong>: Using only the finest tools and products, ensuring every service meets the highest standards.</p>
              </div>
              <div className='d-flex ps-3'>
                <i className="bi bi-caret-right text-white"></i>
                <p><strong>Community</strong>: Building strong relationships with our clients and being a trusted part of the North London community.</p>
              </div>
              <div className='d-flex ps-3'>
                <i className="bi bi-caret-right text-white"></i>
                <p><strong>Innovation</strong>: Staying ahead of trends while honoring classic techniques, offering a perfect blend of the old and new.</p>
              </div>
              <div className='d-flex ps-3'>
                <i className="bi bi-caret-right text-white"></i>
                <p><strong>Inclusivity</strong>: Creating a welcoming space for everyone, where all styles are celebrated, and every client leaves with a smile.</p>
              </div>
            </Reveal>
          </div>

          <div className='about-page-child-section-2 py-4'>
            <Reveal>
              <img src={require('../assets/images/img7.jpg')} alt='out-mission' width='100%' style={{ borderRadius: 15, maxHeight: 500, objectFit: 'contain' }} />
            </Reveal>
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <div className="booking-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcumb-wrap text-center">
                  <h2>Book your appointment</h2>
                  <h6>Schedule your next haircut online now. Our online booking service is available 24/7.</h6>
                  <div><BookingButton /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhyUsSection />

      <div className='container py-5'>
        {/* <h2>Our services</h2> */}
      </div>
    </div>
  )
}

export default Contact
